<template>
  <div>
  <b-card class="chat-widget" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <h5 class="mb-0">
          دردشة
        </h5>
      </div>
    </b-card-header>

    <section class="chat-app-window">
      <!-- User Chat Area -->
      <vue-perfect-scrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
        <chat-log :chat-data="chatData" :user-data="userData" />
      </vue-perfect-scrollbar>

      <!-- Message Input -->
      <b-form v-if="$can('read', 'evaluationRequest')" class="chat-app-form position-relative"
        @submit.prevent="sendMessage">
        <b-input-group  class="input-group-merge full-width form-send-message mr-1">
        <Mentionable  :keys="['@']" :items="items" offset="6" insert-space>
            <b-form-input v-model="chatInputMessage" placeholder="اكتب رسالتك" />
          </Mentionable>
          <b-button style="border-radius: 4px 0 0 4px;" variant="primary" type="submit">
            إرسال
          </b-button>
        </b-input-group>
      </b-form>
    </section>
  </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BInputGroup,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ChatLog from '@/views/modules/chat/Log.vue';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import evaluationRequestStore from '../evaluationRequestStore';
import  Mentionable from '@/components/Mentionable.vue';
import 'floating-vue/dist/style.css'

export default {
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    BFormTextarea,
    // SFC
    ChatLog,

    // 3rd party
    VuePerfectScrollbar,
    Mentionable,
  },
  props: {
    evaluationRequest: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
  {
    value: 'anis',
    label: 'أنيس',
  },
  {
    value: 'posva',
    label: 'Eduardo',
  },
  {
    value: 'atinux',
    label: 'Sébastien',
  },
],
      userData: JSON.parse(localStorage.getItem('userData')) || {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      chatInputMessage: '',
    };
  },
  mounted() {
    this.psToBottom();
  },
  methods: {
    sendMessage() {
      // save message to db
      this.$store
        .dispatch('evaluationRequest/saveMessage', {
          discussionData: {
            message: this.chatInputMessage,
            request_id: this.evaluationRequest.id,
            user_id: this.userData.id || null,
          },
          id: this.evaluationRequest.id,
        })
        .then(() => {
          this.chatData = [
            ...this.chatData,
            {
              message: this.chatInputMessage,
              user_id: this.userData.id,
              user: this.userData,
            },
          ];
          this.chatInputMessage = '';

          // Update scroll position
          // Scroll to bottom
          this.$nextTick(() => {
            this.psToBottom();
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
  },
  setup(props) {
    const EVLUATION_REQUEST_STORE_MODULE_NAME = 'evaluationRequest';

    // Register evaluationRequest
    if (!store.hasModule(EVLUATION_REQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVLUATION_REQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVLUATION_REQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVLUATION_REQUEST_STORE_MODULE_NAME);
    });

    const { discussion } = props.evaluationRequest;

    const chatData = discussion ? discussion.messages : [];
    const users = ref(null);
    store
      .dispatch('evaluationRequest/fetchUsers')
      .then((response) => {
        const { data } = response.data;
        users.value = data.map((c) => ({
          label: c.name,
          value: c.email.split('@')[0], // Extract the part before '@' from the email
          ...c,
        }));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          users.value = undefined;
        }
      })
    return {
      users,
      discussion,
      chatData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
<style>
.v-popper__popper {
  z-index: 10000;
  top: 0;
  left: 0;
  outline: none;
}

.v-popper__popper.v-popper__popper--hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
  pointer-events: none;
}

.v-popper__popper.v-popper__popper--shown {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.v-popper__popper.v-popper__popper--skip-transition,
.v-popper__popper.v-popper__popper--skip-transition > .v-popper__wrapper {
  transition: none !important;
}

.v-popper__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.v-popper__inner {
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
}

.v-popper__inner > div {
  position: relative;
  z-index: 1;
  max-width: inherit;
  max-height: inherit;
}

.v-popper__arrow-container {
  position: absolute;
  width: 10px;
  height: 10px;
}

.v-popper__popper--arrow-overflow .v-popper__arrow-container,
.v-popper__popper--no-positioning .v-popper__arrow-container {
  display: none;
}

.v-popper__arrow-inner,
.v-popper__arrow-outer {
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.v-popper__arrow-inner {
  visibility: hidden;
  border-width: 7px;
}

.v-popper__arrow-outer {
  border-width: 6px;
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner {
  left: -2px;
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-outer,
.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  left: -1px;
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-outer {
  border-bottom-width: 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.v-popper__popper[data-popper-placement^="top"] .v-popper__arrow-inner {
  top: -2px;
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-container {
  top: 0;
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  border-top-width: 0;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-inner {
  top: -4px;
}

.v-popper__popper[data-popper-placement^="bottom"] .v-popper__arrow-outer {
  top: -6px;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner {
  top: -2px;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-outer,
.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  top: -1px;
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  border-left-width: 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-inner {
  left: -4px;
}

.v-popper__popper[data-popper-placement^="right"] .v-popper__arrow-outer {
  left: -6px;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-container {
  right: -10px;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner,
.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-outer {
  border-right-width: 0;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}

.v-popper__popper[data-popper-placement^="left"] .v-popper__arrow-inner {
  left: -2px;
}
</style>